import React, { useState, useEffect } from 'react';
import { Layout } from '../../layout/Layout';
import { Alert, Chip, CircularProgress, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker, DesktopDateTimePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { StyledCreateSmallButton } from '../../styles/ButtonStyles';
import { useDispatch, useSelector } from 'react-redux';
import { assignPersonnel, clearAssignPersonnelStatus, fetchPersonnelTag, viewPersonnel } from '../../../redux/actions/PersonnelAction';
import { fetchSingleTag, fetchTag } from '../../../redux/actions/DeviceActions';
import { fetchSites } from '../../../redux/actions/UserRoleActions';
import { fetchRoute } from '../../../redux/actions/RouteActions';
import CancelIcon from "@mui/icons-material/Cancel";
import { MenuProps } from '../../Styles';
import dayjs from 'dayjs';
import { GmttimeStamptoDate, dateNeglector, dateToTimeStamp, dayjsToGmt, timeStamptoDate } from '../../convertors/TimeConvertor';
import { fetchCheckPointList } from '../../../redux/actions/CheckPointActions';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Kolkata');
export const PersonnelAssign = () => {
    const dispatch = useDispatch();
    const singlePersonnel = useSelector(state => state.personnel.viewPersonnel);
    const assignPersonnelStatus = useSelector(state => state.personnel.assignPersonnel);
    const [showAlert, setShowAlert] = useState(false);

    const siteData = useSelector(state => state.userRole.siteData);
    const tagListData = useSelector(state => state.device.tagList);
    const singleTag = useSelector(state => state.personnel.perssonelTag);
    const routeListData = useSelector(state => state.routes.routeList);
    const checkPointListData = useSelector(state => state.checkpoints.checkPointList);

    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("tracking");
    const [tagData, setTagData] = useState({})
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const [tagList, setTagList] = useState([]);
    const [selectedTag, setSelectedTag] = useState(0);
    const [routeList, setRouteList] = useState([]);
    const [selectedRoutes, setSelectedRoutes] = useState([]);
    const [checkpointList, setCheckpointList] = useState([]);
    const [selectedCheckPoint, setSelectedChekpoint] = useState('');
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState(new Date());
    const [selectedLunchStartTime, setSelectedLunchStartTime] = useState(new Date());
    const [selectedLunchEndTime, setSelectedLunchEndTime] = useState(new Date());
    const [clockStart, setClockStart] = useState(dayjs.utc(new Date()));
    const [clockEnd, setClockEnd] = useState(dayjs.utc(new Date()))
    const [lunchStart, setLunchStart] = useState(null);
    const [lunchEnd, setLunchEnd] = useState(null);
    const [isAnyEmptyPatrol, setIsAnyEmptyPatrol] = useState(false);
    const [isAnyEmptySentry, setIsAnyEmptySentry] = useState(false);
    const [alertMessage, setAlertMessage] = useState('')

    const [personnelData, setPersonnelData] = useState({
        contractorname: '',
        site_id: 0,
        tag_id: 0,
        emp_id: '',
        email: '',
        phone: '',
        organization_name: '',
        sentry_points: '',
        duty_type: 0,
        routes: [],
        sentry_start_time: null,
        sentry_end_time: null,
        sentry_lunch_start_time: null,
        sentry_lunch_end_time: null,
        sentry_maxdurationofbreak: 0,
        sentry_totalbreaktime: 0

    });
    const [validationErrors, setValidationErrors] = useState({
        contractorname: '',
        site_id: '',
        tag_id: '',
        emp_id: '',
        email: '',
        phone: '',
        organization_name: '',
        duty_type: '',
        routes: '',
        sentry_points: '',
        sentry_start_time: '',
        sentry_end_time: '',
        sentry_lunch_start_time: '',
        sentry_lunch_end_time: '',
        sentry_maxdurationofbreak: '',
        sentry_totalbreaktime: ''
    });
    useEffect(() => {
        const id = sessionStorage.getItem('perssonelAssignId');
        dispatch(viewPersonnel(id));
        dispatch(fetchSites())
    }, [])
    useEffect(() => {
        if (!singlePersonnel.isFetching && singlePersonnel.payload && singlePersonnel.payload.success === true) {
            const personnel = singlePersonnel.payload.checkpoint_details;
            const temproutes = personnel.route_Data.map((item) => parseInt(item.id))
            if (!personnel.duty_type == 0) {
                const tempstart = GmttimeStamptoDate(personnel.sentry_starttime);
                const tempend = GmttimeStamptoDate(personnel.sentry_endtime);

                const formattedEndTime = GmttimeStamptoDate(personnel.sentry_lunchendtime)
                const formattedStartTime = GmttimeStamptoDate(personnel.sentry_lunchstarttime);
                setLunchEnd(formattedEndTime);
                setLunchStart(formattedStartTime);
                setSelectedLunchEndTime(personnel.sentry_lunchendtime)
                setSelectedLunchStartTime(personnel.sentry_lunchstarttime)
                setSelectedTime(personnel.sentry_starttime)
                setSelectedEndTime(personnel.sentry_endtime)
                setClockStart(tempstart);
                setClockEnd(tempend);
            }
            else {
                setLunchStart(dayjs('2024-01-24T13:00'))
                setLunchEnd(dayjs('2024-01-24T14:00'));
                setClockStart(dayjs('2024-01-24T08:00'));
                setClockEnd(dayjs('2024-01-24T15:00'));
                let tempLunchStart = dayjsToGmt(dayjs('2024-01-24T13:00'));
                let tempLunchEnd = dayjsToGmt(dayjs('2024-01-24T14:00'));
                let tempSentryStart = dayjsToGmt(dayjs('2024-01-24T08:00'));
                let tempSentryEnd = dayjsToGmt(dayjs('2024-01-24T15:00'));
                setSelectedLunchStartTime(tempLunchStart);
                setSelectedLunchEndTime(tempLunchEnd);
                setSelectedTime(tempSentryStart);
                setSelectedEndTime(tempSentryEnd);
            }
            setPersonnelData(personnel);
            setSelectedSites(personnel.site_id);
            setSelectedRoutes(temproutes);
            setSelectedSites(personnel.site_id);
            setSelectedChekpoint(personnel.check_point_Data.map(item => parseInt(item.id)));
            setSelectedTag(personnel.tag_id)
            let id = personnel.tag_id;
            if (id > 0) {
                dispatch(fetchPersonnelTag(id))
            }
        }
    }, [singlePersonnel]);
    useEffect(() => {
        if (!assignPersonnelStatus.isFetching && assignPersonnelStatus.payload && assignPersonnelStatus.payload.success === true) {
            sessionStorage.setItem('trackingTab', 'three')
            window.location.href = '/tracking';
        }
        if (!assignPersonnelStatus.isFetching && assignPersonnelStatus.error) {

            const error = assignPersonnelStatus.errorMessage.message;
            setAlertMessage(error);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                clearAssignPersonnelStatus();
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [assignPersonnelStatus])
    useEffect(() => {
      
        if (!siteData.isFetching &&
            !tagListData.isFetching &&
            !singleTag.isFetching &&
            singleTag.payload &&
            singleTag.payload.success === true &&
            tagListData.payload &&
            tagListData.payload.success === true &&
            siteData.payload &&
            siteData.payload.success === true) {
            const tempSingleTagData = singleTag.payload.checkpoint_details;
            const siteList = siteData.payload.data.SiteList;
            const tempSites = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(tempSites)
            const siteId = sessionStorage.getItem('user_site_id');
            if (siteId != 0) {
                setSelectedSites(parseInt(siteId))
            }
            setTagData(tempSingleTagData);
            const tempData = tagListData.payload.data;
           
            if (Object.keys(tempSingleTagData).length > 0) {
                let mergerdList = [...tempData];
                if (tempData[0]?.site_id == tempSingleTagData.site_id) {
                    mergerdList.push(tempSingleTagData);
                      }
                if (tempData.length == 0 && tempSingleTagData.site_id == selectedSites) {
                    mergerdList.push(tempSingleTagData);
                    setTagList(mergerdList);
                }
                if (tempData[0]?.site_id !== tempSingleTagData.site_id) {
                    setTagList(tempData);
                  }
                setTagList(mergerdList);
            }
            else {
                if (tempData.length) {
                    setTagList(tempData);
                }
                else
                    setTagList([]);
            }

        }
        else if (!siteData.isFetching &&
            !tagListData.isFetching &&
            tagListData.payload &&
            tagListData.payload.success === true &&
            siteData.payload &&
            siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const tempSites = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(tempSites)
            const tempData = tagListData.payload.data;
            
            if (tempData.length) {
                setTagList(tempData);
            }
            else
                setTagList([]);

        }
        if (!singleTag.isFetching && singleTag.error) {
            setTagData({});
        }

    }, [siteData, tagListData, singleTag]);
    useEffect(() => {
        if (!checkPointListData.isFetching && checkPointListData.payload && checkPointListData.payload.success === true) {
            const checkPoints = checkPointListData.payload.data;

            if (checkPoints?.length && checkPoints[0].site_id != selectedSites) {
                setSelectedChekpoint([]);
            }
            else if (!checkPoints) {
                setSelectedChekpoint([]);

            }
            else if (checkPoints.length != 0 && checkPoints[0].site_id == selectedSites) {
                const temp = [];
                checkPoints.map((items) => {
                    if (items.checkpoint_type !== 2) {
                        temp.push(items)


                    }
                })
                setCheckpointList(temp)
            }
            else {
                const temp = [];
                checkPoints.map((items) => {
                    if (items.checkpoint_type !== 2) {
                        temp.push(items)


                    }
                })
                setCheckpointList(temp)
            }
        }
    }, [checkPointListData, selectedSites])
    useEffect(() => {
       
        let data = {
            start: 0,
            limit: 1000,
            site_id: selectedSites,
            is_assign: 0
        }
        dispatch(fetchTag(data))
        if (selectedSites != personnelData.site_id) {
            setSelectedTag(0)
        }

       

    }, [selectedSites]);
    useEffect(() => {
        if (personnelData && personnelData.duty_type === 2 || personnelData.duty_type === 4) {
            let data = {
                start: 0,
                limit: 1000,
                site_id: selectedSites,
                route_type:personnelData.duty_type
            }
            dispatch(fetchRoute(data));
        }
        if (personnelData && personnelData.duty_type === 1) {
            setSelectedRoutes([]);
            let data = {
                start: 0,
                limit: 1000,
                site_id: selectedSites,
            }
            dispatch(fetchCheckPointList(data));
        }

    }, [personnelData.duty_type, selectedSites])
    useEffect(() => {
        if (!routeListData.isFetching && routeListData.payload && routeListData.payload.success === true) {
            const routes = routeListData.payload.data;
            const tempRoutes = routes.map(item => {
                return {
                    id: item.id,
                    routename: item.routename

                }
            })
            if (routes?.length && routes[0].site_id != personnelData.site_id) {
                setSelectedRoutes([]);
            }
            if (!routes.length) {
                setSelectedRoutes([]);

            }
            setRouteList(tempRoutes);
        }
    }, [routeListData]);
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    
    const onHandleChange = (value, key) => {
        let temp = { ...personnelData };
        temp[key] = value;

        setPersonnelData(temp);
        let error = '';
        switch (key) {
            case 'personnelName':
            case 'organisation_name':

                error = value <= 0
                    ? 'value must be higher than zero'
                    : '';
                break;

            default:
                break;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: error,
        }));

    }
    const onUpdate = () => {
        const selectedRoutes_id = selectedRoutes.map(item => String(item));
        const selectedCheckPoint_id = selectedCheckPoint.map(item => String(item))
        const tempStartDate = dateToTimeStamp(selectedTime);
        const tempEndDate = dateToTimeStamp(selectedEndTime);
        let data = {
            id: parseInt(sessionStorage.getItem('perssonelAssignId')),
            tag_id: selectedTag,
            site_id: selectedSites,
            // startdate: selectedTime,
            // enddate: selectedEndTime,
            routes: personnelData.duty_type === 1 ? [] : selectedRoutes_id,
            duty_type: personnelData.duty_type,
            sentry_points: personnelData.duty_type === 2 ? [] : selectedCheckPoint_id,
            sentry_start_time: personnelData.duty_type === 2 ? 0 : selectedTime,
            sentry_end_time: personnelData.duty_type === 2 ? 0 : selectedEndTime,
            sentry_lunch_start_time: personnelData.duty_type === 2 ? 0 : selectedLunchStartTime,
            sentry_lunch_end_time: personnelData.duty_type === 2 ? 0 : selectedLunchEndTime,
            max_break_duration: personnelData.duty_type === 2 ? 0 : parseInt(personnelData.sentry_maxdurationofbreak),
            total_break_time: personnelData.duty_type === 2 ? 0 : parseInt(personnelData.sentry_totalbreaktime),
            organization_id: parseInt(sessionStorage.getItem('org_Id')),
            created_user: parseInt(sessionStorage.getItem('id'))

        }
        const isPetrolClear = (data) => {
            if (data.duty_type === 2 || data.duty_type === 0) {
                if (data.tag_id == 0 || data.site_id == 0 || data.duty_type === 0 || data.routes.length == 0) {
                    setIsAnyEmptyPatrol(true);
                    setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        tag_id: selectedTag == 0 ? 'This field is mandatory' : '',
                        site_id: selectedSites == 0 ? 'This field is mandatory' : '',
                        duty_type: personnelData.duty_type == 0 ? 'This field is mandatory' : '',
                        routes: selectedRoutes.length > 0 ? '' : 'This field is mandatory',
                    }))
                    return true;
                }
                else {
                    setIsAnyEmptyPatrol(false);
                    return false
                }
            }
            if (data.duty_type === 1) {
                if (data.tag_id == 0 || data.site_id == 0 || selectedCheckPoint == ''
                ) {
                    setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        tag_id: data.tag_id == 0 ? 'This field is mandatory' : '',
                        site_id: data.site_id == 0 ? 'This field is mandatory' : '',
                        duty_type: data.duty_type == 0 ? 'This field is mandatory' : '',
                        sentry_points: selectedCheckPoint != '' ? '' : 'This field is mandatory',
                       
                    }))
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        const isPetrolClearChecker = isPetrolClear(data);
        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (!isPetrolClearChecker && allClear) {
            dispatch(assignPersonnel(data));
        }
    }
    const handleLunchEnd = (newTime) => {
        const tempDate = newTime.$d.toISOString();
        const unixTimestamp = Date.parse(tempDate) / 1000;
        setSelectedLunchEndTime(unixTimestamp)
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                sentry_lunch_end_time: ''
            }))
        };
    }
    const handleLunchStart = (newTime) => {
        const tempDate = newTime.$d.toISOString();
        const unixTimestamp = Date.parse(tempDate) / 1000;
        //  const tempStart = dateNeglector(newTime);
        setSelectedLunchStartTime(unixTimestamp);
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                sentry_lunch_start_time: ''
            }))
        };
    }
    const handleEndTimeChange = (newTime) => {
        const tempDate = newTime.$d.toISOString();
        const unixTimestamp = Date.parse(tempDate) / 1000;
        setSelectedEndTime(unixTimestamp);
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                sentry_end_time: ''
            }))
        };
    };
    const handleTimeChange = (newTime) => {
        const tempDate = newTime.$d.toISOString();
        const unixTimestamp = Date.parse(tempDate) / 1000;

        setSelectedTime(unixTimestamp);
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                sentry_start_time: ''
            }))
        };
    }
    const handleRouteSelect = (selectedValue) => {
        if (selectedValue !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                routes: ''
            }))
        };
        setSelectedRoutes(selectedValue)
    }
    const handleSelectedChekpoints = (selectedValue) => {
        if (selectedValue != '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                sentry_points: ''
            }))
        };
        setSelectedChekpoint(selectedValue)
    }
    const handleSelectedTag = (selectedValue) => {
        if (selectedValue !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                tag_id: ''
            }))
        };
        setSelectedTag(selectedValue)
    }
    const handleSelectedSites = (selectedValue) => {
        if (selectedValue !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                site_id: ''
            }))
        };
        setSelectedSites(selectedValue)
    }
    const closeSnack = () => {
        setShowAlert(false)
    }
    return (
        <>
            <Layout
                isDarkMode={isDarkMode}
                handleDarkmode={handleDarkmode}
                handleActiveMenuItems={handleActiveMenuItems}
                activeMenuItem={"tracking"}
                activeMenuKey={"tracking"}
                isCreate={true}
                activeSubtab={"three"}
            >
                {showAlert ?
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={showAlert}
                        onClose={closeSnack}
                        autoHideDuration={3000}
                    //  key={vertical + horizontal}
                    >
                        <Alert variant="outlined" severity="error"
                            sx={{ width: 'auto', color: 'red', backgroundColor: 'white' }}

                        >{alertMessage}</Alert>
                    </Snackbar> : <></>
                }
{assignPersonnelStatus.isFetching || singlePersonnel.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
                <Grid container spacing={2} sx={{ mt: 3, pb: 5 }}
                    className={isDarkMode ? "table_dark ml" : "table_light ml"} >
                    <Grid item lg={12} >
                        <Typography
                            className='device_view_head'>Assign {personnelData.contractorname}</Typography>
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Site
                        </InputLabel>
                        <Select
                            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                            value={selectedSites}
                            onChange={(e) => handleSelectedSites(e.target.value)}
                            displayEmpty
                            error={!!validationErrors.site_id}
                        >
                            {sites.map((site, index) => (
                                <MenuItem
                                    key={index}
                                    value={site.organizationId}>
                                    {site.organizationName}
                                </MenuItem>
                            ))}
                        </Select>
                        {validationErrors.site_id && (
                            <Typography
                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                className={
                                    isDarkMode
                                        ? 'errormargin  errorStyle '
                                        : ' errormargin errorStyle'
                                }
                            >
                                {validationErrors.site_id}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Tag

                        </InputLabel>
                        <Select
                            className={isDarkMode ? "form_field_right form_div " : "form_field_right form_field_light"}
                            value={selectedTag}
                            onChange={(e) => handleSelectedTag(e.target.value)}
                            error={!!validationErrors.tag_id}
                        >
                            {tagList.length && tagList !== 'undefined' ?
                                tagList.map((tag, index) => (
                                    <MenuItem
                                        key={index}
                                        value={tag.tag_id}>
                                        {tag.infant_name}
                                    </MenuItem>
                                ))
                                :
                                <MenuItem>No Tags Found</MenuItem>
                            }

                        </Select>
                        {validationErrors.tag_id && (
                            <Typography
                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                className={
                                    isDarkMode
                                        ? 'errormargin  errorStyle '
                                        : ' errormargin errorStyle'
                                }
                            >
                                {validationErrors.tag_id}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Assignment Type</InputLabel>
                        <Select
                            className={personnelData.duty_type > 0 ? isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"
                                : isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml disabled_label_light"}
                            value={personnelData.duty_type}
                            onChange={(e) => { onHandleChange(e.target.value, 'duty_type') }}
                            error={!!validationErrors.duty_type}

                        >
                            <MenuItem value={0} disabled>Select Assignment Type</MenuItem>
                            <MenuItem value={1}>Adhoc Schedule </MenuItem>
                            <MenuItem value={2}>Fix Schedule</MenuItem>
                            <MenuItem value={4}>Cleaner Schedule</MenuItem>

                        </Select>
                        {validationErrors.duty_type && (
                            <Typography
                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                className={
                                    isDarkMode
                                        ? 'errormargin  errorStyle '
                                        : ' errormargin errorStyle'
                                }
                            >
                                {validationErrors.duty_type}
                            </Typography>
                        )}
                    </Grid>
                    {/* <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                    {JSON.stringify(selectedRoutes)}
                    </Grid> */}
                    {personnelData.duty_type === 1 ?
                        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                Sentry Points</InputLabel>
                            <Select
                                className={isDarkMode ? "form_field_right form_div " : "form_field_right form_field_light"}
                                value={selectedCheckPoint}
                                onChange={(e) => handleSelectedChekpoints(e.target.value)}
                                error={!!validationErrors.sentry_points}
                                multiple
                                displayEmpty
                                renderValue={(selected) => <Stack gap={1} direction="row" flexWrap="wrap">


                                    {checkpointList.length && checkpointList !== undefined ?
                                        selected.map(key => <Chip
                                            key={key}
                                            label={checkpointList.find(opt => opt.id == key) ?
                                                checkpointList.find(opt => opt.id == key).check_point_name : ""}
                                            onDelete={() =>
                                                setSelectedChekpoint(
                                                    selectedCheckPoint.filter((item) => item != key)
                                                )
                                            }
                                            deleteIcon={
                                                <CancelIcon
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                />
                                            }
                                        />)
                                        : <></>
                                    }
                                </Stack>
                                }
                            >
                                {checkpointList.length && checkpointList !== 'undefined' ?
                                    checkpointList.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.check_point_name}
                                        </MenuItem>
                                    )) :
                                    <MenuItem value="">
                                        No data found
                                    </MenuItem>
                                }
                            </Select>
                            {validationErrors.sentry_points && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.sentry_points}
                                </Typography>
                            )}
                        </Grid>
                        :

                        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                            <InputLabel
                                className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                Route

                            </InputLabel>

                            <Select
                                className={isDarkMode ? "form_field_right form_div " : "form_field_right form_field_light"}
                                multiple
                                value={selectedRoutes}
                                onChange={(e) => handleRouteSelect(e.target.value)}
                                displayEmpty
                                renderValue={(selected) =>
                                 <Stack gap={1} direction="row" flexWrap="wrap">


                                    {routeList.length && routeList !== undefined ?
                                        selected.map(key => <Chip
                                            key={key}
                                            label={routeList.find(opt => opt.id == key) ?
                                                routeList.find(opt => opt.id == key).routename : ""}
                                            onDelete={() =>
                                                setSelectedRoutes(
                                                    selectedRoutes.filter((item) => item != key)
                                                )
                                            }
                                            deleteIcon={
                                                <CancelIcon
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                />
                                            }
                                        />)
                                        : <></>
                                    }
                                </Stack>
                                }

                                style={{ marginBottom: '10px' }}
                                MenuProps={MenuProps}
                                error={!!validationErrors.routes}
                            >
                                {routeList.length && routeList !== 'undefined' ?
                                    routeList.map((item, index) => (
                                        <MenuItem key={index}
                                            value={item.id}
                                        // selected={selectedRoutes.some((selectedItem) => selectedItem.id === item.id)}
                                        >
                                            {item.routename}
                                        </MenuItem>
                                    ))
                                    :
                                    <MenuItem value="">
                                        No data found
                                    </MenuItem>
                                }
                            </Select>
                            {validationErrors.routes && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.routes}
                                </Typography>
                            )}
                        </Grid>
                    }
                    {personnelData.duty_type === 1 ?
                        <>    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <Grid container>
                                <Grid item xs={10} sm={10} lg={5.4} md={5.4}>
                                    <InputLabel
                                        className={isDarkMode ? "form_text " : "form_text_light "}
                                        sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                        Start Time
                                    </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} className="datetime-container" >
                                        <DemoContainer
                                            className="datetime-container"
                                            sx={{ pt: 0, width: "215px !important" }} components={['TimePicker']}>
                                            <TimePicker
                                                slotProps={{
                                                    textField: {
                                                        helperText: validationErrors.sentry_start_time,
                                                        error: validationErrors.sentry_start_time !== '' ? true : false
                                                    },

                                                }}
                                                //minDateTime={dayjs()}


                                                value={clockStart}
                                                onChange={handleTimeChange}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={10} sm={10} lg={5.5} md={5.5}>
                                    <InputLabel
                                        className={isDarkMode ? "form_text " : "form_text_light "}
                                        sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                        End Time </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer sx={{ pt: 0, width: "215px !important" }} components={['TimePicker']}>
                                            <TimePicker
                                                slotProps={{
                                                    textField: {
                                                        helperText: validationErrors.sentry_end_time,
                                                        error: validationErrors.sentry_end_time !== '' ? true : false
                                                    },

                                                }}
                                                // minDateTime={dayjs()}

                                                value={clockEnd}
                                                onChange={handleEndTimeChange}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                                <Grid container>
                                    <Grid item xs={10} sm={10} lg={5.8} md={5.8}>
                                        <InputLabel
                                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                            Lunch Start Time</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} className="datetime-container" >
                                            <DemoContainer
                                                className="datetime-container"
                                                sx={{ pt: 0, width: "215px !important" }} components={['TimePicker']}>
                                                <TimePicker
                                                    slotProps={{
                                                        textField: {
                                                            helperText: validationErrors.sentry_lunch_start_time,
                                                            error: validationErrors.sentry_lunch_start_time !== '' ? true : false
                                                        },

                                                    }}
                                                    // minDateTime={dayjs()}


                                                    value={lunchStart}
                                                    onChange={handleLunchStart}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={10} sm={10} lg={5} md={5} sx={{ ml: -2.6 }} >
                                        <InputLabel
                                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                            Lunch End Time</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DemoContainer sx={{ pt: 0, width: "215px !important" }} components={['TimePicker']}>

                                                <TimePicker
                                                    slotProps={{
                                                        textField: {
                                                            helperText: validationErrors.sentry_lunch_end_time,
                                                            error: validationErrors.sentry_lunch_end_time !== '' ? true : false
                                                        },

                                                    }}
                                                    //  minDateTime={dayjs()}

                                                    value={lunchEnd}
                                                    onChange={handleLunchEnd}

                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                                <InputLabel
                                    className={isDarkMode ? "form_text" : "form_text_light"}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    Total break time (Mins)</InputLabel>
                                <TextField
                                    className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                    type="number"
                                    value={personnelData.sentry_totalbreaktime}
                                    onChange={(e) => { onHandleChange(e.target.value, 'sentry_totalbreaktime') }}
                                    error={!!validationErrors.sentry_totalbreaktime}
                                    helperText={validationErrors.sentry_totalbreaktime}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                                <InputLabel
                                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                    Maximum duration for a single break(Mins)</InputLabel>
                                <TextField
                                    className={isDarkMode ? "form_field_right form_div " : "form_field_right form_field_light"}

                                    value={personnelData.sentry_maxdurationofbreak}
                                    onChange={(e) => { onHandleChange(e.target.value, 'sentry_maxdurationofbreak') }}
                                    error={!!validationErrors.sentry_maxdurationofbreak}
                                    helperText={validationErrors.sentry_maxdurationofbreak}
                                >

                                </TextField>
                            </Grid>
                        </>
                        : null
                    }

                    <Grid item tem xs={10} sm={10} lg={10} md={10} >
                        <StyledCreateSmallButton
                            onClick={onUpdate}
                            sx={{ mt: 5, width: 150 }} variant="contained"
                            disableRipple>
                            Update
                        </StyledCreateSmallButton>
                    </Grid>


                </Grid>
              }  
            </Layout>
        </>
    )
}