import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Alert, Box, Chip, CircularProgress, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography, useTheme } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fetchCheckPointList } from "../../../redux/actions/CheckPointActions";
import { DraggableItem } from "./drag&drop/DraggableItem";
import { DroppableItem } from "./drag&drop/DroppableItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import dayjs from "dayjs";
import { BorderTransparentButton, StyledCreateSmallButton } from "../../styles/ButtonStyles";
import { addRoute, clearAddRouteStatus } from "../../../redux/actions/RouteActions";
import { MenuProps, RouteSwitch, Search, SearchIconWrapper, StyledInputBase } from "../../Styles";
import { SmallSearch } from "../../styles/SerachStyles";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
export const RouteCreate = ({ }) => {
    const [isDarkMode, setDarkMode] = useState(false);
    const theme = useTheme();
    const [sites, setSites] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
    const [selectedSites, setSelectedSites] = useState('');
    const [checkPoints, setCheckPoints] = useState([]);
    const [selectedCheckPoints, setSelectedCheckPoints] = useState([]);
    const [allRoutes, setAllRoutes] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [isFollow, setIsFollow] = useState(false)
    const [search, setSearch] = useState('');
    const [endDateLimit, setEndDateLimit] = useState(null);
    const [alertMessage, setAlertMessage] = useState('')
    const [endClockTime, setEndClockTime] = useState(null)
    const [routeDetails, setRouteDetails] = useState({
        route_name: "",
        start_time: null,
        end_time: null,
        organization_id: parseInt(sessionStorage.getItem('org_Id')),
        week_days: "",
        site_id: "",
        check_point_id: "",
        isOrder: false,
        route_type: ''
    });
    const [validationErrors, setValidationErrors] = useState({
        route_name: '',
        site_id: '',
        check_point_id: '',
        week_days: '',
        end_time: '',
        start_time: '',
        route_type: ''
    })
    const siteData = useSelector(state => state.userRole.siteData);
    const checkPointListData = useSelector(state => state.checkpoints.checkPointList);
    const newRouteStatus = useSelector(state => state.routes.newRoute);

    const dispatch = useDispatch();
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);

    const handleEndTimeChange = (newTime) => {

        const formattedTime = dayjs(newTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const tempDate = newTime.$d.toISOString();
        setEndClockTime(newTime)
        setSelectedEndTime(tempDate);
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                end_time: '',
            }));
        }
        if (newTime < endDateLimit) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                end_time: 'Invalid End Date',
            }));
        }
        else {
            const startTimePlus24Hours = dayjs(endDateLimit).add(24, 'hour');
            if (newTime > startTimePlus24Hours) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    end_time: 'Invalid End Date Selection. End date should not be 24 hours greater than start date.',
                }));
            }
        }
    };
    const handleTimeChange = (newTime) => {
        const tempDate = newTime.$d.toISOString();
        setSelectedTime(tempDate);
        setEndDateLimit(newTime)
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                start_time: '',
            }));
        }
        if (newTime > endClockTime) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                end_time: 'Invalid End Date',
            }));
        }
        else {
            const startTimePlus24Hours = dayjs(newTime).add(24, 'hour');
            if (endClockTime > startTimePlus24Hours) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    end_time: 'Invalid End Date Selection. End date should not be  24 hours greater than start date.',
                }));
            }
        }
    };
    useEffect(() => {
        dispatch(fetchSites());

        // dispatch(fetchCheckPointList());
    }, []);
    useEffect(() => {
        if (selectedSites) {
            let data = {
                site_id: selectedSites,
                type: routeDetails.route_type
            }
            dispatch(fetchCheckPointList(data));
            setSelectedCheckPoints([])
            if (selectedSites !== '') {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    site_id: '',
                }));
            }
        }
    }, [selectedSites, routeDetails.route_type])
    useEffect(() => {

        if (selectedCheckPoints.length) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                check_point_id: '',
            }));
        }
    }, [selectedCheckPoints])
    useEffect(() => {
        if (!checkPointListData.isFetching && checkPointListData.payload && checkPointListData.payload.success === true) {
            const tempCheckPoints = checkPointListData.payload.data;

            const temp = [];
            tempCheckPoints.map((items) => {
                if (items.checkpoint_type !== 1) {
                    temp.push(items)


                }
            })
            setCheckPoints(temp);

            setAllRoutes(temp);
        }
    }, [checkPointListData])
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
            const siteId = sessionStorage.getItem('user_site_id');
            //   setSelectedSites(parseInt(siteId))
            if (siteId != 0) {
                setSelectedSites(parseInt(siteId))
            }
            setSelectedCheckPoints([])
        }
    }, [siteData])
    useEffect(() => {
        if (!newRouteStatus.isFetching && newRouteStatus.payload && newRouteStatus.payload.success === true) {
            sessionStorage.setItem('trackingTab', 'two')
            window.location.href = '/tracking';
        }
        if (!newRouteStatus.isFetching && newRouteStatus.error) {
            const error = newRouteStatus.errorMessage.message;
            setAlertMessage(error);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearAddRouteStatus());

            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [newRouteStatus])
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    const handleRoute = (value, key) => {
        console.log("value", value)
        let temp = { ...routeDetails };
        temp[key] = value;
        setRouteDetails(temp)
        if (key === 'route_name') {
            const valueLength = value.trim().length;
            const nameLengthError =
                valueLength < 2 || valueLength >= 30
                    ? 'Route name must be between 2 and 30 characters long'
                    : '';

            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                route_name: nameLengthError,
            }));
        }


    }
    const handleChange = (event) => {
        const selectedValue = event.target.value;
        // If "all" is selected, set all days
        if (selectedValue.includes('All')) {
            setSelectedDays(['All']);
        } else {
            // Remove "all" if it was selected before
            setSelectedDays(selectedValue.filter(day => day !== 'All'));
        }
        if (selectedValue !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                week_days: ''
            }))
        };
    }
    const onCreateRoute = (e) => {
        const check_point_id = selectedCheckPoints.map(checkpoint => String(checkpoint.id));
        let tempdata = {
            route_name: routeDetails.route_name,
            start_time: selectedTime,
            end_time: selectedEndTime,
            organization_id: parseInt(sessionStorage.getItem('org_Id')),
            week_days: selectedDays,
            site_id: parseInt(selectedSites),
            check_point_id: check_point_id,
            is_order: routeDetails.route_type != 4 ? isFollow ? 1 : 0 : 0,
            created_user: parseInt(sessionStorage.getItem('id')),
            route_type: parseInt(routeDetails.route_type)
            // organization_id: parseInt(sessionStorage.getItem('org_Id')),
        }
        e.preventDefault();
        const isEmptyField = Object.values(tempdata).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
        });
        const isemptyArray = !tempdata.week_days.length || !tempdata.check_point_id.length;
        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (isemptyArray) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                check_point_id: check_point_id.length ? '' : 'This field is mandatory',
                week_days: selectedDays.length ? '' : 'This field is mandatory',
            }));
        }
        if (isEmptyField) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                route_name: routeDetails.route_name.trim() === '' ? 'This field is mandatory' : prevErrors.personnelName,
                site_id: selectedSites ? '' : 'This field is mandatory',
                week_days: selectedDays.length ? '' : 'This field is mandatory',
                end_time: selectedEndTime ? '' : 'This field is mandatory',
                start_time: selectedTime ? '' : 'This field is mandatory',
                check_point_id: check_point_id.length ? '' : 'This field is mandatory',
                route_type: routeDetails.route_type.trim() === '' ? 'This field is mandatory' : prevErrors.route_type,
            }));

        }
        if (!isemptyArray && !isEmptyField && allClear) {

            setValidationErrors({
                checkPointName: '',
                site_id: '',
                week_days: '',
                end_time: '',
                start_time: '',
                check_point_id: '',
                route_type: ""
            });
            dispatch(addRoute(tempdata));

        }
    }

    const onDragEnd = (results) => {
        const { source, destination, type, draggableId } = results;
        if (!destination) return;
        if (destination.droppableId === null) return;
        if (source.droppableId != destination.droppableId) {
            const item = allRoutes.find(item => item.id == Number(draggableId));
            if (source.droppableId == "allcheckpoint") {
                let sourceItems = [...checkPoints];
                let destinationItems = [...selectedCheckPoints];
                sourceItems.splice(source.index, 1);
                destinationItems.splice(destination.index, 0, { ...item, order: destination.index });




                setSelectedCheckPoints(destinationItems);
                setCheckPoints(sourceItems);
            } else {
                let sourceItems = [...selectedCheckPoints];
                let destinationItems = [...checkPoints];
                sourceItems.splice(source.index, 1);
                destinationItems.splice(destination.index, 0, item);
                setSelectedCheckPoints(sourceItems);
                setCheckPoints(destinationItems);

            }
        }
        else {
            if (type === "group" && source.droppableId == "allcheckpoint") {
                const reorderedCheckPoints = [...checkPoints];

                const checkPointsSourceIndex = source.index;
                const checkPointsDestinatonIndex = destination.index;

                const [removedcheckPoints] = reorderedCheckPoints.splice(checkPointsSourceIndex, 1);
                reorderedCheckPoints.splice(checkPointsDestinatonIndex, 0, removedcheckPoints);

                return setCheckPoints(reorderedCheckPoints);
            }
            if (type === "group" && source.droppableId !== "allcheckpoint") {
                const reorderedCheckPoints = [...selectedCheckPoints];

                const checkPointsSourceIndex = source.index;
                const checkPointsDestinatonIndex = destination.index;

                const [removedcheckPoints] = reorderedCheckPoints.splice(checkPointsSourceIndex, 1);
                reorderedCheckPoints.splice(checkPointsDestinatonIndex, 0, { ...removedcheckPoints, order: checkPointsDestinatonIndex });

                return setSelectedCheckPoints(reorderedCheckPoints);
            }
        }
        if (!destination) return;
        // 
        // if (
        //     source.droppableId === destination.droppableId &&
        //     source.index === destination.index
        // )
        //     return;




    }

    const handleDragOver = (event) => {
        const draggedOverElement = event.target;
        // Perform actions based on the draggedOverElement
    };
    const handleDeleteCheckPoint = (id) => {
        let temp = [];
        let tempadd = [...checkPoints]
        selectedCheckPoints.map((item) => {
            if (item.id !== id) {
                temp.push(item);
            }
            else {
                tempadd.push(item)
            }
        })
        setCheckPoints(tempadd);
        setSelectedCheckPoints(temp);
    }
    const handleAddCheckpoints = (id) => {
        let temp = [];
        let tempadd = [...selectedCheckPoints];
        checkPoints.map((item) => {
            if (item.id !== id) {
                temp.push(item)
            }
            else
                tempadd.push(item)

        })
        setCheckPoints(temp);
        setSelectedCheckPoints(tempadd)
    }
    const removeAll = () => {
        let temp = [...checkPoints]
        selectedCheckPoints.map((item) => {
            temp.push(item);
        })

        setSelectedCheckPoints([]);
        setCheckPoints(temp);
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <>
            <Layout
                isDarkMode={isDarkMode}
                handleDarkmode={handleDarkmode}
                handleActiveMenuItems={handleActiveMenuItems}
                activeMenuItem={"tracking"}
                activeMenuKey={"tracking"}
                activeSubtab={"two"}
                isCreate={true}
            >

                <Snackbar
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={handlealertClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handlealertClose}
                        severity="error"
                        variant="outlined"
                        sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
                {newRouteStatus.isFetching ?
                    <Box
                        sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Box>
                    :
                    <Grid container spacing={2} sx={{ mt: 3, pb: 5 }} className={isDarkMode ? "table_dark ml" : "table_light ml"} >
                        <Grid item lg={12} >
                            <Typography
                                className='device_view_head'>Create Route</Typography>
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Route Name</InputLabel>
                            <TextField
                                className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                                placeholder="Create route name"
                                value={routeDetails.route_name}
                                onChange={(e) => handleRoute(e.target.value, 'route_name')}
                                error={!!validationErrors.route_name}
                                helperText={validationErrors.route_name}
                            />
                        </Grid>
                        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                            <InputLabel
                                className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                Sites</InputLabel>
                            <Select
                                className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                                value={selectedSites}
                                onChange={(e) => setSelectedSites(e.target.value)}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected == '') {
                                        return <span style={{ color: '#a2a2a2' }}>Site</span>;
                                    }

                                    const selectedSite = sites.find(site => site.organizationId === selected);

                                    // Return the organizationName of the selected site
                                    return selectedSite ? selectedSite.organizationName : '';
                                }}
                                error={!!validationErrors.site_id}
                            >
                                {sites.length > 0 ?
                                    sites.map((site, index) => (
                                        <MenuItem
                                            key={index}
                                            value={site.organizationId}>
                                            {site.organizationName}
                                        </MenuItem>
                                    ))
                                    : <MenuItem value="">No Data Found</MenuItem>}
                            </Select>
                            {validationErrors.site_id && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.site_id}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Type</InputLabel>
                            <Select
                                className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                value={routeDetails.route_type}
                                onChange={(e) => handleRoute(e.target.value, 'route_type')}
                                error={!!validationErrors.route_type}
                                helperText={validationErrors.route_type}
                            >

                                <MenuItem
                                    value={'2'}>
                                    Patrol
                                </MenuItem>
                                <MenuItem
                                    value={'4'}>
                                    Cleaner
                                </MenuItem>
                            </Select>
                            {validationErrors.route_type && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.route_type}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Day</InputLabel>

                            <Select
                                className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                multiple
                                value={selectedDays}
                                onChange={handleChange}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <span style={{ color: '#a2a2a2' }}>Select Day</span>;
                                    }

                                    return (

                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    onDelete={() =>
                                                        setSelectedDays(
                                                            selectedDays.filter((item) => item !== value)
                                                        )
                                                    }
                                                    deleteIcon={
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                />
                                            ))}
                                        </Stack>
                                    )
                                }}
                                style={{ marginBottom: '10px' }}
                                MenuProps={MenuProps}
                                error={!!validationErrors.week_days}
                            >
                                {['All', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                                    <MenuItem key={index} value={day}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Select>
                            {validationErrors.week_days && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.week_days}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <Grid container>
                                <Grid item xs={10} sm={10} lg={5.8} md={5.8}>
                                    <InputLabel
                                        className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                        sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                        Start Date & Time</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} className="datetime-container" >
                                        <DemoContainer
                                            className="datetime-container"
                                            // sx={{ pt: 0, width: "215px !important" }} components={['DateTimePicker']}>
                                            // <DateTimePicker
                                            //     // className="timepicker"
                                            //     viewRenderers={{
                                            //         hours: renderTimeViewClock,
                                            //         minutes: renderTimeViewClock,
                                            //         seconds: renderTimeViewClock,
                                            //     }}
                                            //     minDateTime={dayjs()}


                                            //     //value={selectedTime}
                                            //     onChange={handleTimeChange}

                                            sx={{ pt: 0, width: "215px !important" }} components={['DesktopDateTimePicker']}>
                                            <DesktopDateTimePicker
                                                // className="timepicker"



                                                slotProps={{
                                                    textField: {
                                                        helperText: validationErrors.start_time,
                                                        error: validationErrors.start_time !== '' ? true : false
                                                    },

                                                }}
                                                onChange={(value) => handleTimeChange(value)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={10} sm={10} lg={5} md={5} sx={{ ml: -2.6, '@media (max-width: 600px)': { ml: 0, mt: 2 } }} >
                                    <InputLabel
                                        className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                        sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                        End Date & Time</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer sx={{ pt: 0, width: "215px !important" }} components={['DesktopDateTimePicker']}>
                                            <DesktopDateTimePicker
                                                // className="timepicker"


                                                onChange={(value) => handleEndTimeChange(value)}

                                                slotProps={{
                                                    textField: {
                                                        helperText: validationErrors.end_time,
                                                        error: validationErrors.end_time !== '' ? true : false
                                                    },

                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>

                            </Grid>
                        </Grid>
                        {/* <Grid item xs={10} sm={10} lg={5} md={5} sx={{ ml: -2.6 }} >
                        {JSON.stringify(checkPoints)}
                        </Grid> */}
                        <Grid item xs={12} sm={12} lg={11.2} md={11.2} sx={{ mt: 3, }}
                            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                        >
                            <div className="drag_box">
                                <Grid container>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="allcheckpoint" type="group">
                                            {(provided) => (

                                                <Grid item sm={6} sx={{ pb: 20, overflowY: "auto", height: "350px", position: "relative", }}
                                                    ref={provided.innerRef} {...provided.droppableProps} >
                                                    <div style={{
                                                        position: "sticky",
                                                        top: 0, // Stick to the top

                                                        zIndex: 3, backgroundColor: '#ffffff'
                                                    }}>
                                                        <InputLabel
                                                            className={isDarkMode ? "form_text" : "form_text_light"}
                                                            sx={{
                                                                color: isDarkMode ? "white" : "black", ml: 0, mb: 4,

                                                            }}>
                                                            All Check Points</InputLabel>
                                                        <SmallSearch isDarkMode={isDarkMode} theme={theme}

                                                        >
                                                            <SearchIconWrapper>
                                                                <SearchIcon sx={{ color: isDarkMode ? "white" : "#8F8F91" }} fontSize="medium" className="mb" />
                                                            </SearchIconWrapper>
                                                            <StyledInputBase
                                                                isDarkMode={isDarkMode}
                                                                placeholder="Search check point"
                                                                inputProps={{ 'aria-label': 'search' }}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                                value={search}
                                                            />
                                                        </SmallSearch>
                                                    </div>
                                                    {checkPoints.filter(item => {
                                                        return item.check_point_name.toLowerCase().includes(search.toLowerCase())
                                                    }).map((item, index) => (
                                                        <div key={"chkey" + index}>
                                                            <DraggableItem
                                                                id={item.id}
                                                                key={item.id.toString()}
                                                                index={index}
                                                                checkPoints={item.check_point_name}
                                                                handleAddCheckpoints={handleAddCheckpoints} />
                                                        </div>
                                                    ))}
                                                    {provided.placeholder}
                                                </Grid>

                                            )}
                                        </Droppable>
                                        <Droppable droppableId="routecheckpoint" type="group">
                                            {(provided) => (
                                                <Grid item sm={6}
                                                    sx={{ pb: 20, overflowY: "auto", height: "350px", pl: 5, position: "relative", }}
                                                    ref={provided.innerRef} {...provided.droppableProps}
                                                    onDragOver={handleDragOver}>
                                                    <div style={{
                                                        position: "sticky",
                                                        top: 0, // Stick to the top
                                                        paddingBottom: '14%',
                                                        zIndex: 3, backgroundColor: '#ffffff'
                                                    }}>
                                                        <InputLabel
                                                            className={isDarkMode ? "form_text" : "form_text_light"}
                                                            sx={{
                                                                color: isDarkMode ? "white" : "black", ml: 0, mb: 4,
                                                                position: "sticky",
                                                                top: 0, // Stick to the top
                                                                zIndex: 3, backgroundColor: '#ffffff'
                                                            }}>
                                                            Route Check Points</InputLabel>
                                                        {validationErrors.check_point_id && (
                                                            <Typography
                                                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                                                className={
                                                                    isDarkMode
                                                                        ? 'errormargin  errorStyle '
                                                                        : ' errormargin errorStyle'
                                                                }
                                                            >
                                                                {validationErrors.check_point_id}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                    {selectedCheckPoints.map((item, index, array) => (
                                                        <DroppableItem
                                                            lastIndex={index === array.length - 1 ? true : false} id={item.id}
                                                            key={item.id.toString()}
                                                            index={index}
                                                            checkPoints={item.check_point_name}
                                                            handleDeleteCheckPoint={handleDeleteCheckPoint}
                                                            isOrder={isFollow}
                                                        />
                                                    ))}
                                                    {provided.placeholder}

                                                </Grid>
                                            )}
                                        </Droppable>


                                    </DragDropContext>
                                    {selectedCheckPoints.length ?
                                        <Grid container lg={12} md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Grid item sx={{ mr: '13%' }}>
                                                <BorderTransparentButton
                                                    onClick={removeAll}
                                                >
                                                    Remove All
                                                </BorderTransparentButton>
                                            </Grid>
                                        </Grid> : <></>
                                    }
                                </Grid>
                            </div>
                        </Grid >
                        {routeDetails.route_type != 4 ?
                            <Grid item xs={10} sm={10} lg={12} md={12} sx={{ mt: 3 }} className="route_switch">
                                <Typography className="modalhead">
                                    Strictly follow order
                                </Typography>
                                <RouteSwitch
                                    checked={isFollow}
                                    onChange={() => setIsFollow(!isFollow)}
                                />
                            </Grid>
                            : <></>
                        }
                        <Grid item>
                            <StyledCreateSmallButton
                                onClick={onCreateRoute}
                                sx={{ mt: 5, width: 150 }} variant="contained"
                                disableRipple>
                                Create
                            </StyledCreateSmallButton>
                        </Grid>
                    </Grid >
                }
            </Layout >
        </>
    )
}