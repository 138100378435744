import { Grid, Typography } from "@mui/material";
import React from "react";
import { UserDetails } from "./UserDetails";
import { alertTimeOnly, convertMinutesToTime, dateOnly, sensorDatefieldConvertor, timeOnly, timeStampToTime } from "../../convertors/TimeConvertor";
export const AlertDeatils = ({ AlertDetails, AlertDate }) => {
    

    return (
        <div className='device_view reducepb' >
            <Grid container >
                <Grid item container sx={{ mb: 1 }}>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                            <Typography className='device_view_text label_color rowdiv_label'>Type</Typography>
                            <Typography className='device_view_text content_color rowdiv_text'>
                                {AlertDetails.duty_type === 1 ? "Sentry Violations" : ""}
                                {AlertDetails.duty_type === 2 ? "Patrol Violations" : ""}
                                {AlertDetails.duty_type === 0 ? "Device Violations" : ""}
                                {AlertDetails.duty_type === 5 ? "Sensor Violations" : ""}
                                {AlertDetails.duty_type === 4 ? "VA Violations" : ""}
                                {AlertDetails.duty_type === 6 ? "Cleaner Violations" : ""}



                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }}>
                            <Typography className='device_view_text label_color rowdiv_label'>
                                Violation
                            </Typography>
                            <Typography className='device_view_text content_color rowdiv_text '>
                                {AlertDetails.message}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className={AlertDetails.duty_type === 5 ? AlertDetails.description != "" ?'rowdiv borderbottom rowdiv_mr':'rowdiv  rowdiv_mr' : 'rowdiv borderbottom rowdiv_mr'} style={{ paddingBottom: "20px" }} >
                        <Typography className='device_view_text label_color rowdiv_label'>Stage</Typography>
                        <Typography className='device_view_text content_color rowdiv_text negativemb3'>
                            {AlertDetails.alert_type == 1 ?
                                <div className="alert_stage_box alert_critical" >
                                    <span className=" critical_text"> critical</span>
                                </div> : <></>
                            }
                            {AlertDetails.alert_type == 2 ?
                                <div className="alert_stage_box alert_medium" >
                                    <span className=" medium_text"> Medium</span>
                                </div>
                                : <></>
                            }
                            {AlertDetails.alert_type == 3 ?
                                <div className="alert_stage_box alert_low" >
                                    <span className=" low_text"> Low</span>
                                </div>
                                : <></>
                            }
                        </Typography>
                    </div>
                </Grid>
{AlertDetails.duty_type != 5 ? 
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className={AlertDetails.duty_type == 5 ?  AlertDetails.description != "" ? 'rowdiv borderbottom rowdiv_ml' : 'rowdiv  rowdiv_ml' :'rowdiv borderbottom rowdiv_ml'} style={{ paddingBottom: "20px" }}>
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Date
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                            {AlertDetails.duty_type == 0 ?
                                dateOnly(AlertDetails.timestamp) + "," + timeOnly(AlertDetails.timestamp)
                                : AlertDetails.duty_type === 4 ? AlertDate + ","+alertTimeOnly(AlertDetails.timestamp): AlertDate}

                        </Typography>
                    </div>
                </Grid> :<></>
                }
                  <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className={AlertDetails.duty_type == 5 ?  AlertDetails.description != "" ? 'rowdiv borderbottom rowdiv_ml' : 'rowdiv  rowdiv_mr' :'rowdiv borderbottom rowdiv_mr'} style={{ paddingBottom: "20px" }} >
                            <Typography className='device_view_text label_color rowdiv_label'>
                            Triggered Date & Time
                            </Typography>
                            <Typography className='device_view_text content_color rowdiv_text'>
                              
                            {sensorDatefieldConvertor(AlertDetails.created_at)}
                            </Typography>
                        </div>
                    </Grid>
              
                {/* {AlertDetails.duty_type == 5 && (
                   < Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className={AlertDetails.duty_type == 5 ?  AlertDetails.description != "" ? 'rowdiv borderbottom rowdiv_ml' : 'rowdiv  rowdiv_ml' :'rowdiv borderbottom rowdiv_ml'} style={{ paddingBottom: "20px" }}>
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Date & Time
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                            {sensorDatefieldConvertor(AlertDetails.created_at)}

                        </Typography>
                    </div>
                </Grid>
               ) } */}
                {
                    AlertDetails.duty_type === 5 && (
                        AlertDetails.description != "" &&
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv  rowdiv_mr' style={{ paddingBottom: "20px" }}>
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            About
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {AlertDetails.description}

                                        </Typography>
                                    </div>
                                </Grid>
                            
                    )
                }
                {
                    AlertDetails.duty_type === 0 ?
                        <>
                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "47px" }}>
                                    <Typography className='device_view_text label_color rowdiv_label'>
                                        Listener
                                    </Typography>
                                    <Typography className='device_view_text content_color rowdiv_text '>
                                        {AlertDetails.listenerName}

                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }} >
                                    <Typography className='device_view_text label_color rowdiv_label'>Site</Typography>
                                    <Typography className='device_view_text content_color rowdiv_text '>
                                        {AlertDetails.site_name}
                                    </Typography>
                                </div>
                            </Grid>

                           
                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className={AlertDetails.description != "" ? 'rowdiv borderbottom rowdiv_ml' :
                                    'rowdiv borderbottom rowdiv_ml'} style={{ paddingBottom: "20px" }}>
                                    <Typography className='device_view_text label_color rowdiv_label'>
                                        Level
                                    </Typography>
                                    <Typography className='device_view_text content_color rowdiv_text '>
                                        {AlertDetails.level_name}

                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className={AlertDetails.description != "" ? 'rowdiv borderbottom rowdiv_ml' :
                                    'rowdiv  rowdiv_ml'} style={{ paddingBottom: "20px" }}>
                                    <Typography className='device_view_text label_color rowdiv_label'>Block</Typography>
                                    <Typography className='device_view_text content_color rowdiv_text'>
                                        {AlertDetails.block_name}
                                    </Typography>
                                </div>
                            </Grid>
                            {AlertDetails.description != "" &&
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv  rowdiv_mr' style={{ paddingBottom: "20px" }}>
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            About
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {AlertDetails.description}

                                        </Typography>
                                    </div>
                                </Grid>
                            }
                        </>
                        : <></>
                }
                {
                    AlertDetails.duty_type === 1 ||  AlertDetails.duty_type == 6 ?
                        <>
                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }}>
                                    <Typography className='device_view_text label_color rowdiv_label'>
                                        Start Time & End Time
                                    </Typography>
                                    <Typography className='device_view_text content_color rowdiv_text '>
                                        {/* { AlertDetails.sentry_starttime == 0 ? "00:00" :  convertMinutesToTime(AlertDetails.sentry_starttime) +" To "+ AlertDetails.sentry_endtime == 0 ? "00:00" : convertMinutesToTime(AlertDetails.sentry_endtime)} */}

                                        {AlertDetails.duty_type === 1 ?
                                         AlertDetails.start_time_sentry + " To " + AlertDetails.end_time_sentry
                                        : 
                                        AlertDetails.patrol_start_time + " To " + AlertDetails.patrol_end_time
}

                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                                    <Typography className='device_view_text label_color rowdiv_label'>Site</Typography>
                                    <Typography className='device_view_text content_color rowdiv_text '>
                                        {AlertDetails.site_name}
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className={AlertDetails.description != "" ? 'rowdiv borderbottom rowdiv_ml' :
                                    'rowdiv borderbottom rowdiv_ml'} style={{ paddingBottom: "20px" }}>
                                    <Typography className='device_view_text label_color rowdiv_label'>
                                        Level
                                    </Typography>
                                    <Typography className='device_view_text content_color rowdiv_text '>
                                        {AlertDetails.level_name}

                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className={AlertDetails.description != "" ? 'rowdiv  rowdiv_mr' :
                                    'rowdiv  rowdiv_mr'} style={{ paddingBottom: "20px" }}>
                                    <Typography className='device_view_text label_color rowdiv_label'>Block</Typography>
                                    <Typography className='device_view_text content_color rowdiv_text'>
                                        {AlertDetails.block_name}
                                    </Typography>
                                </div>
                            </Grid>
                            {AlertDetails.description != "" &&
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv  rowdiv_ml' style={{ paddingBottom: "20px" }}>
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                        About
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {AlertDetails.description}

                                        </Typography>
                                    </div>
                                </Grid>
                            }
                        </> : <></>
                }
                {
                    AlertDetails.duty_type === 2 || AlertDetails.duty_type === 4 ?
                        <>


                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "47px" }} >
                                    <Typography className='device_view_text label_color rowdiv_label'>Site</Typography>
                                    <Typography className='device_view_text content_color rowdiv_text '>
                                        {AlertDetails.site_name}
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className={AlertDetails.description == "" ? 'rowdiv rowdiv_mr ' :
                                    'rowdiv borderbottom rowdiv_mr'} style={{ paddingBottom: "20px" }}>
                                    <Typography className='device_view_text label_color rowdiv_label'>
                                        Level
                                    </Typography>
                                    <Typography className='device_view_text content_color rowdiv_text '>
                                        {AlertDetails.level_name}

                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                <div className={AlertDetails.description == "" ?'rowdiv  rowdiv_ml' : 'rowdiv borderbottom rowdiv_ml'} 
                                style={{ paddingBottom: "20px" }}>
                                    <Typography className='device_view_text label_color rowdiv_label'>Block</Typography>
                                    <Typography className='device_view_text content_color rowdiv_text'>
                                        {AlertDetails.block_name}
                                    </Typography>
                                </div>
                            </Grid>
                            {AlertDetails.description != "" &&
                                <Grid item sx={{ pb: 7,}} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv  rowdiv_ml' style={{ paddingBottom: "20px" }}>
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                        About
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {AlertDetails.description}

                                        </Typography>
                                    </div>
                                </Grid>
                            }
                        </> : <></>
                }
            </Grid>
        </div>
    )
}