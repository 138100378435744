import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import { CustomizedTableCell, CustomizedTableRow } from "../../../styles/TablebButtonStyles";
export const CleanerHistoryTable = ({ row}) => {
    return (
        <div className='table_Scroll ml2back'>
            <Table className="table_mob" sx={{  width: "100%" }} >
                <TableHead>
                    <TableRow>
                        <CustomizedTableCell>Date</CustomizedTableCell>
                        <CustomizedTableCell>Start Time & End Time</CustomizedTableCell>
                        <CustomizedTableCell>First Seen</CustomizedTableCell>
                        <CustomizedTableCell>Last Seen</CustomizedTableCell>
                        <CustomizedTableCell>Site</CustomizedTableCell>
                        <CustomizedTableCell>Route</CustomizedTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                {row.map((item,index)=>(
                    <CustomizedTableRow key={index}>
                        <CustomizedTableCell >{item.date}</CustomizedTableCell>
                        <CustomizedTableCell>
                        {item.patrol_start_time} To {item.patrol_end_time}
                      </CustomizedTableCell>
                                              <CustomizedTableCell  >{item.first_seen}</CustomizedTableCell>
                        <CustomizedTableCell  >{item.last_seen}</CustomizedTableCell>
                        <CustomizedTableCell  >{item.site}</CustomizedTableCell>
                        <CustomizedTableCell  > 
                            <span className="route_text_div" >{item.routename}</span>
                        </CustomizedTableCell>

                        </CustomizedTableRow>
                ))}  
                </TableBody>
            </Table>
        </div>
    )
}